import React from "react";
import { Container } from "react-bootstrap";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Banner from "./components/Banner";
import Footer from "./components/Footer";

import HomeScreen from "./screens/HomeScreen";
import Dameskleding from "./screens/Dameskleding";
import Herenkleding from "./screens/Herenkleding";
import Kinderkleding from "./screens/Kinderkleding";
import WollenProducten from "./screens/WollenProducten";
import OverDewolster from "./screens/OverDewolster";
import Contact2 from "./screens/Contact2";
import Danku from "./screens/Danku";

function App() {
  const base_url = process.env.REACT_APP_URL
  console.warn(base_url);
  return (
    <Router>
      <Header />
      <Banner
        imgL={`${base_url}/static/images/_MG_0891.jpg`}
        imgM={`${base_url}/static/images/road_sign.jpg`}
        imgR={`${base_url}/static/images/shop_interior.jpg`}
      />
      <main className="py-3">
        <Container>
          <Routes>
            <Route path="/" element={<HomeScreen />} exact />
            <Route path="/dameskleding" element={<Dameskleding />} />
            <Route path="/herenkleding" element={<Herenkleding />} />
            <Route path="/kinderkleding" element={<Kinderkleding />} />
            <Route path="/wollenproducten" element={<WollenProducten />} />
            <Route path="/overdewolster" element={<OverDewolster />} />
            <Route path="/contact" element={<Contact2 />} />
            <Route path="/danku" element={<Danku />} />
          </Routes>
        </Container>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
