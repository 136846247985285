import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { LinkContainer, Link } from "react-router-bootstrap";

function Footer() {
  const base_url = process.env.REACT_APP_BASE_URL;
  return (
    <footer>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css"
        integrity="sha512-DTOQO9RWCH3ppGqcWaEA1BIZOC6xxalwEsw9c2QQeAIftl+Vegovlnee1c9QX4TctnWMn13TZye+giMm8e2LwA=="
        crossorigin="anonymous"
        referrerpolicy="no-referrer"
      />
      <Container>
        <Row>
          <Col className="text-center py-3">
            <LinkContainer to="/">
              <a>
                <img
                  loading="lazy"
                  style={{ maxHeight: "130px" }}
                  src={`${base_url}/static/images/logo-footer.png`}
                />
              </a>
            </LinkContainer>
            <p>_______________________________</p>
            <h2>OPENINGSTIJDEN</h2>
            <p></p>

            <p>
              DONDERDAG 10:00-16:00 <br />
              ZATERDAG 13:00-16:00
            </p>
            <p>BUITEN OPENINGSTIJDEN OP AFSPRAAK</p>
          </Col>
        </Row>

        <Row>
          <Col className="text-center py-3 col-md-4">
            <h2>ADRES</h2>
            <p>
              Van Helomaweg 8<br />
              7971 PX Havelte
            </p>
          </Col>
          <Col className="py-3 col-md-4 d-flex gap-4 justify-content-center">
            <a
              href="https://www.facebook.com/dewolster/"
              target="_blank"
              rel="noreferrer">
              <i className="fa-brands fa-facebook fa-xl"></i>
            </a>
            <a
              href="https://www.instagram.com/de.wolster/"
              target="_blank"
              rel="noreferrer">
              <i className="fa-brands fa-instagram fa-xl"></i>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=0612574637&text="
              target="_blank"
              rel="noreferrer">
              <i className="fa-brands fa-whatsapp fa-xl"></i>
            </a>
          </Col>
          <Col className="text-center py-3 col-md-4">
            <h2>CONTACT</h2>
            <p>
              E-mail :{" "}
              <a href="mailto: dewolster@gmail.com">dewolster@gmail.com</a>
              <br />
              Tel : +31 612574637
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
