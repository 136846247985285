import React, { useEffect } from 'react'

function Danku() {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return (
    <div class="card mb-3 border-light">
        <div class="card-body text-center" style={{fontSize:'125%'}}>
          <h3 class="card-title py-3">Dank u</h3>
          <p>Uw bericht is succesvol verzonden. Er wordt zo snel mogelijk contact met u opgenomen.<br/>Controleer uw inbox voor de bevestigingsmail.</p>
        </div>
    </div>
  )
}

export default Danku
