import React from 'react'
import { Nav, Navbar, Container} from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

function Header() {
    const base_url = process.env.REACT_APP_URL
  return (
      <header className='centered'>  
        {/* <Navbar class="navbar navbar-expand-lg bg-light" data-bs-theme="light" collapseOnSelect> */}
        <Navbar expand="xl" variant='dark' data-bs-theme="dark" collapseOnSelect>
            <Container fluid>
                <LinkContainer to='/'>
                    <Navbar.Brand ><img loading='lazy' style={{ maxHeight:'40px' }} src={`${base_url}/static/images/dewolster_logo.png`}/></Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                <Nav
                    className="navbar-nav m-auto my-2 my-lg-10"
                    // style={{ maxHeight: '100px' }}
                    navbarScroll>
                        
                    <LinkContainer to='/'>
                        <Nav.Link>HOME</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to='/dameskleding'>
                        <Nav.Link>DAMESKLEDING</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to='/herenkleding'>
                        <Nav.Link>HERENKLEDING</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to='/kinderkleding'>
                        <Nav.Link class="nav-link">KINDERKLEDING</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to='/wollenproducten'>
                        <Nav.Link>WOLLEN PRODUCTEN</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to='/overdewolster'>
                        <Nav.Link>OVER DE WOLSTER</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to='/contact'>
                        <Nav.Link>CONTACT</Nav.Link>
                    </LinkContainer>
                </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        
    </header>
  )
}

export default Header
