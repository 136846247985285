import React, { useEffect } from 'react'
import ImageGallery from '../components/ImageGallery'

function Dameskleding() {
  const base_url = process.env.REACT_APP_URL
  console.warn(process.env)

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  const galleryImages = [
    {
      img: `${base_url}/static/images/DameskledingGallery1.jpg`,
    },
    {
      img: `${base_url}/static/images/DameskledingGallery2.jpg`,
    },
    {
      img: `${base_url}/static/images/DameskledingGallery3.jpg`,
    },
    {
      img: `${base_url}/static/images/DameskledingGallery4.jpg`,
    },
  ]

  return (
    <div>
      <div class="card mb-3 border-light">
        <div class="card-body text-center" style={{fontSize:'125%'}}>
          <h4 class="card-title py-3" style={{fontSize:'150%'}}>Unieke duurzame dameskleding</h4>
          <p class="card-text">Wanneer je op zoek bent naar duurzame dameskleding, dan ben je bij De Wolster aan het juiste adres. Je kunt hier terecht voor een ruim assortiment aan prachtige kleding van natuurlijke materialen. De dameskleding is duurzaam geproduceerd. Ben je nieuwsgierig geworden naar het aanbod? Lees dan vooral verder, we vertellen je hier graag meer over.</p>
          <h4 class="card-title py-3" style={{fontSize:'150%'}}>Leuke kleding voor jong en oud</h4>
          <p class="card-text">Bij De Wolster vind je een ruime collectie van wolzijde onderkleding. Dit is de basiscollectie en die is er voor het hele gezin. In de winter heeft De Wolster veel keuze in wollen dameskleding. Daarnaast is er een ruime selectie van linnen, katoen, tencel, zijde en EcoVero voor dames van jong tot oud, in alle soorten en maten. Alle kleding is volledig duurzaam en de meeste merken die wij verkopen zijn GOTS-gecertificeerd. De kleding is gemaakt van heerlijke én eerlijke materialen; fijn voor jou en de aarde.</p>
          <p class="card-text">In ons assortiment vind je onderkleding zoals ondergoed, bh's, leggings, broeken en rokken. Bijvoorbeeld de wollen leggings van Jalfe en Reiff, het wolzijde ondergoed van Engel, GOTS-gecertificeerde spijkerbroeken van Tranquillo en diverse linnen en wollen rokken van o.a Heidekonigin en Matona. Ook hebben we diverse bovenkleding zoals blouses, hemden, shirts, truien, vesten, jassen en jurken. O.a. wollen truien van Aran, wollen vesten van Naturalmente, wolzijde onderkleding van Engel en Hocosa, zijde hemden en onderjurkjes van Alkena, biokatoen shirts van Jalfe en Tranquillo en leuke, hippe jurken van verschillende materialen en merken. De kleding is tijdloos en zul je lang kunnen dragen.</p>
          <ImageGallery
          galleryImages={galleryImages}
          />
          <h4 class="card-title py-3" style={{fontSize:'150%'}}>Persoonlijke service</h4>
          <p class="card-text">Met grote zorg proberen wij 2x per jaar een gevarieerde en uitgebreide collectie voor De Wolster samen te stellen. Daarbij kijken we naar duurzaamheid, materialen en welke merken goed aansluiten. Maar vooral, wat past er bij de verschillende doelgroepen? Zodat er een mooie, kleurrijke collectie hangt voor jong en oud, voor alle figuren en stijlen, van XS t/m XXl.</p>
          <p class="card-text">Onze kledingstukken zijn uniek, deze zul je dus niet snel bij iemand anders zien. Je bent van harte welkom in de winkel. Hier kun je onder het genot van een kopje koffie of thee op je gemak alles bekijken, voelen en passen. Ook voorzien we je, waar gewenst, van een persoonlijk en eerlijk advies. Hangt jouw maat of kleur er niet meer bij, vraag dan naar de mogelijkheden om het te bestellen. Dit is veel gevallen mogelijk!</p>
          <h4 class="card-title py-3" style={{fontSize:'150%'}}>Neem contact met ons op</h4>
          <p class="card-text">Ben je op zoek naar een wollen kledingwinkel voor dames, heren of kinderen? Dan ben je aan het juiste adres bij De Wolster. Heb je vragen over het aanbod of wil je graag een afspraak maken? Neem dan vrijblijvend contact op. Hier kun je bellen naar 06-12574637 of vul een bericht in het via het contactformulier op deze website. We staan je zo snel mogelijk te woord. Graag tot ziens bij De Wolster!</p>
        </div>
      </div>
    </div>
  )
}

export default Dameskleding
