import React, { useEffect } from 'react'
import ImageGallery from '../components/ImageGallery'

function Kinderkleding() {
  const base_url = process.env.REACT_APP_URL

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  const galleryImages = [
    {
      img: `${base_url}/static/images/KinderkledingGallery1.jpg`,
    },
    {
      img: `${base_url}/static/images/KinderkledingGallery2.jpg`,
    },
    {
      img: `${base_url}/static/images/KinderkledingGallery3.jpg`,
    },
    {
      img: `${base_url}/static/images/KinderkledingGallery4.jpg`,
    },
  ]

  return (
    <div>
      <div>
      <div class="card mb-3 border-light">
        <div class="card-body text-center" style={{fontSize:'125%'}}>
          <h4 class="card-title py-3" style={{fontSize:'150%'}}>Leuke duurzame kinderkleding</h4>
          <p class="card-text">Ben je op zoek naar duurzame baby- en kinderkleding? Je hoeft niet verder te zoeken, je hebt De Wolster gevonden! Hier kun je terecht voor advies, wat heeft mijn baby nodig, wat is er allemaal aan wolletjes en hoe voelt het! Ook is er ruime keuze in duurzame kleding voor baby’s en kinderen. Deze kleding is gemaakt van natuurlijke materialen, zoals wol, wolzijde, katoen en linnen. Voor de gevoelige huid hebben we ook een kleine selectie 100% zijde van Hocosa en Alkena. </p>
          <p class="card-text">Ben je nieuwsgierig geworden naar het aanbod aan kinderkleding? Lees dan vooral verder, we vertellen je hier meer over.</p>
          <ImageGallery galleryImages={galleryImages} />
          <h4 class="card-title py-3" style={{fontSize:'150%'}}>Heerlijk warm en comfortabel</h4>
          <div class='row justify-content-md-center align-middle'>
            <div class="col-sm-8" style={{textAlign:'right'}}>
              <p class="card-text">Bij De Wolster vind je een assortiment met de leukste, duurzame kinderkleding voor jongens en meisjes; van baby’s tot aan de wat grotere kinderen. We hebben diverse wolzijde onderkleding in de collectie zoals rompertjes, mutsjes, shirtjes en leggings en ook wollen sokjes en slofjes. De wolzijde kledingstukken voor kinderen en baby’s zijn heerlijk zacht en houden jouw kindje lekker warm. Warmte is belangrijk voor het lichaam om te groeien, deze heerlijke onderkleding draagt hieraan bij.</p>
            </div>
            <div class="col-sm-4">
                <ImageGallery galleryImages={[{img: `${base_url}/static/images/KinderkledingSingle1.jpg` }]}/>
            </div>
          </div>
          <div class='row justify-content-md-center align-middle'>
            <div class="col-sm-4">
                <ImageGallery galleryImages={[{img: `${base_url}/static/images/KinderkledingSingle2.jpg`}]}/>
            </div>
            <div class="col-sm-8" style={{textAlign:'left'}}>
              <p class="card-text">Bij De Wolster vind je een assortiment met de leukste, duurzame kinderkleding voor jongens en meisjes; van baby’s tot aan de wat grotere kinderen. We hebben diverse wolzijde onderkleding in de collectie zoals rompertjes, mutsjes, shirtjes en leggings en ook wollen sokjes en slofjes. De wolzijde kledingstukken voor kinderen en baby’s zijn heerlijk zacht en houden jouw kindje lekker warm. Warmte is belangrijk voor het lichaam om te groeien, deze heerlijke onderkleding draagt hieraan bij.</p>
            </div>
          </div>
          <p class="card-text">Daarnaast hebben we diverse wollen kleding zoals truien, jassen en broeken, maar ook wolfleece slaapzakken, dekens en overalls. Ook voor kleding gemaakt van biologisch katoen en linnen kun je bij De Wolster terecht. In het assortiment vind je tevens kleding waar barnsteen in verwerkt is. De belangrijke kenmerken van amber of barnsteen zijn: thermoregulerend, anti-allergisch, kalmerend en rustgevend. De kleding is verkrijgbaar in verschillende soorten en maten, zo is er voor iedereen wel iets leuks te vinden. </p>
          <h4 class="card-title py-3" style={{fontSize:'150%'}}>Persoonlijke service</h4>
          <p class="card-text">Je bent van harte welkom in de winkel. Hier kun je onder het genot van een kopje koffie of thee op je gemak alles bekijken, voelen en passen. Indien je vragen hebt over de collectie beantwoorden we deze graag. Ook voorzien we je, waar gewenst, van een persoonlijk en eerlijk advies. </p>
          <h4 class="card-title py-3" style={{fontSize:'150%'}}>Neem contact met ons op</h4>
          <p class="card-text">Ben je op zoek naar duurzame herenkleding? Dan kun je uitstekend terecht bij De Wolster. Heb je vragen over het aanbod of wil je graag een afspraak maken? Neem dan vrijblijvend contact op. Hiervoor kun je bellen naar 06-12574637 of een bericht achterlaten in het contactformulier op deze website. We staan je zo snel mogelijk te woord. Graag tot ziens bij De Wolster!</p>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Kinderkleding
