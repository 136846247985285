import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import ImageTile from "../components/ImageTile";
import TextTile from "../components/TextTile";
import ImageGallery from "../components/ImageGallery";
import axios from "axios";
import Event from "../components/Event";

function HomeScreen() {
  const base_url = process.env.REACT_APP_URL;
  console.warn(base_url);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const [events, setEvents] = useState([]);

  useEffect(() => {
    async function fetchEvents() {
      const { data } = await axios.get("/api/events");
      setEvents(data);
      console.log(data);
    }
    fetchEvents();
  }, []);

  const galleryImages = [
    {
      img: `${base_url}/images/HomeScreenGallery1.jpg`,
    },
    {
      img: `${base_url}/static/images/HomeScreenGallery2.jpg`,
    },
    {
      img: `${base_url}/static/images/HomeScreenGallery3.jpg`,
    },
    {
      img: `${base_url}/static/images/HomeScreenGallery4.jpg`,
    },
  ];

  const poster = [{ img: `${base_url}/static/images/Lentemarkt_24-1.PNG` }];

  return (
    <div>
      <div class="card mb-3 border-light pb-3">
        <div class="card-body text-center" style={{ fontSize: "125%" }}>
          <h4 class="card-title py-3" style={{ fontSize: "150%" }}>
            De Wolster: dé wollen kledingwinkel in Havelte
          </h4>
          <p class="card-text">
            Welkom bij De Wolster, een biologische kledingwinkel in Havelte.
            Wanneer je op zoek bent naar duurzame kleding ben je bij De Wolster
            zeker aan het juiste adres. In het Wolsterwinkeltje vind je een ruim
            assortiment aan ecologische kleding van natuurlijke materialen, in
            diverse soorten en maten. Ook hebben we een ruim aanbod van wollen
            onderkleding voor het hele gezin. Met een oude, rode brandweerbus
            staat de Wolster regelmatig op duurzame markten.
          </p>
          <p class="card-text">
            De Wolster: “Pure en eerlijke kleding voor jong en oud”.
          </p>
          <h4 class="card-title py-3" style={{ fontSize: "150%" }}>
            Duurzaam en uniek
          </h4>
          <p class="card-text">
            Bij De Wolster kun je terecht voor natuurlijk boven- en ondergoed.
            Wij bieden je een ruime collectie van dames-, heren- en
            kinderkleding. Al onze kleding is duurzaam geproduceerd en gemaakt
            van de meest hoogwaardige materialen. Daarnaast zijn de meeste
            merken die wij verkopen GOTS-gecertificeerd . Er is een mooie
            collectie wolzijde ondergoed voor het hele gezin, van baby tot man.
            Onze kleding is uniek en zul je niet snel ergens anders voorkomen.
          </p>
          <h4 class="card-title py-3" style={{ fontSize: "150%" }}>
            persoonlijke service
          </h4>
          <p class="card-text">
            Je bent van harte welkom in de winkel. Hier kun je onder het genot
            van een kopje koffie van je gemak alles bekijken, voelen en
            passagiers. Indien je vragen hebt over de collectie beantwoorden we
            deze graag. Ook voorzien van een persoonlijk en eerlijk advies. We
            doen graag alles aan om jou met een glimlach de winkel te laten
            verlaten.
          </p>
          <h4 class="card-title py-3" style={{ fontSize: "150%" }}>
            Neem contact met ons op
          </h4>
          <p class="card-text">
            Ben je op zoek naar een wollen kledingwinkel voor dames, heren of
            kinderen? Dan ben je aan het juiste adres bij De Wolster. Heb je
            vragen over het aanbod of wil je graag een afspraak maken? Neem dan
            vrijblijvend contact op. Hier kun je bellen naar 06-12574637 of vul
            een bericht in het via het contactformulier op deze website. We
            staan je zo snel mogelijk te woord. Graag tot ziens bij De Wolster!
          </p>
          <h4 class="card-title py-3" style={{ fontSize: "150%" }}>
            Agenda
          </h4>
          {/* <p class="card-text">Donderdag 9 mei GESLOTEN, ivm Hemelvaartsdag</p> */}
          <Row className="flex centered">
            {events.length > 0 ? (
              events.map((event) => (
                <Col sm={12} md={6} lg={4} xl={3}>
                  <Event event={event} />
                </Col>
              ))
            ) : (
              <div></div>
            )}
          </Row>

          <p class="card-text">
            Als de Wolstertruck op een markt staat, dan is de winkel helaas
            gesloten! Een groot gedeelte van de hele collectie is dan mee in de
            bus en ik ben dan daar natuurlijk ook.
          </p>
        </div>
      </div>

      <div class="row justify-content-md-center">
        <div class="col-lg-6">
          <TextTile
            title="Unieke kleding"
            content="Bij De Wolster vind je een ruim assortiment aan dames-, heren en kinderkleding in verschillende soorten en maten. Onze kleding is uniek en kom je niet snel ergens anders tegen. "
          />
        </div>
        <div class="col-lg-6">
          <ImageTile src={`${base_url}/static/images/photo1.jpg`} />
        </div>
      </div>

      <div class="row justify-content-md-center">
        <div class="col-lg-6">
          <ImageTile src={`${base_url}/static/images/photo2.jpg`} />
        </div>
        <div class="col-lg-6">
          <TextTile
            title="Duurzame kleding"
            content="Al onze kleding is duurzaam geproduceerd en gemaakt van natuurlijke materialen van de hoogste kwaliteit. Ben je nieuwsgierig geworden naar ons aanbod? Lees dan vooral verder!"
          />
        </div>
      </div>

      <div class="row justify-content-md-center">
        <div class="col-lg-6">
          <TextTile
            title="Eerlijk advies"
            content="We heten je van harte welkom om eens rond te kijken in onze winkel. Hier voorzien we je graag van persoonlijk en eerlijk advies onder het genot van een lekker kopje koffie of thee."
          />
        </div>
        <div class="col-lg-6">
          <ImageTile src={`${base_url}/static/images/photo3.jpg`} />
        </div>
      </div>

      <Col>
        <Row>
          <h2>
            <br />
            Bekijk enkele items uit onze collectie
          </h2>
        </Row>
        <Row>
          <ImageGallery galleryImages={galleryImages} />
        </Row>
      </Col>
    </div>
  );
}

export default HomeScreen;
