import React, { useEffect } from 'react'
import ImageGallery from '../components/ImageGallery'

function Herenkleding() {
  const base_url = process.env.REACT_APP_URL

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  const galleryImages = [
    {
      img: `${base_url}/static/images/HerenkledingGallery1.jpg`,
    },
    {
      img: `${base_url}/static/images/HerenkledingGallery2.jpg`,
    },
  ]

  const singleImage = [
    {
      img: `${base_url}/static/images/Herenkleding_singleImage.jpg`,
    }
  ]


  return (
    <div>
      <div>
      <div class="card mb-3 border-light">
        <div class="card-body text-center" style={{fontSize:'125%'}}>
          <h4 class="card-title py-3" style={{fontSize:'150%'}}>Unieke duurzame herenkleding</h4>
          <p class="card-text">De Wolster krijgt steeds meer vraag naar duurzame herenkleding en dat is heerlijk om te horen! Bewuste keuzes maken wat betreft kleding is natuurlijk iets wat wij erg stimuleren. Sinds 2 jaar heeft De Wolster een ruime keuze in warme, wollen truien en langzamerhand komt er steeds wat bij!</p>
          <p class="card-text">In ons assortiment vind je al vele jaren de wolzijde onderkleding van Engel en Hocosa. De herenkleding wordt duurzaam geproduceerd van natuurlijke materialen. Ben je nieuwsgierig geworden naar het aanbod aan herenkleding? Lees dan vooral verder, we vertellen je hier meer over.</p>
          <h4 class="card-title py-3" style={{fontSize:'150%'}}>Tijdloos en uniek</h4>
          <img loading='lazy' className='wrap-text-around-image mx-3' src={`${base_url}/static/images/Herenkleding_singleImage.jpg`}/>
          <p class="card-text">Bij De Wolster kun je terecht voor een uitgebreide collectie van wollen of wolzijde onderkleding, zoals hemden, T-shirts, longsleeve, boxershorts en leggings. Engel Sport heeft een prachtige lijn voor mannen en daar hangt af en toe wat van in de winkel, maar is ook heel goed te bestellen. In de zomer is er vanaf nu een selectie van linnen kleding voor heren. Matona heeft mooie unisex overhemden en broeken. In de winter zijn er prachtige wollen truien en vesten voor heren, oa. van Aran, Reiff en Jalfe. Ook zijn er warme, wolfleece jassen van Reiff en Engel en de wollen sokken van Hirsch.</p>
          <p class="card-text">De duurzame herenkleding is tijdloos en zal een lange tijd met je meegaan. De kledingstukken zijn verkrijgbaar in verschillende soorten en maten, zo zit er voor iedereen wel wat leuks tussen. De kleding is uniek en je zal dan ook niet snel iemand anders hiermee zien lopen.</p>
          <h4 class="card-title py-3" style={{fontSize:'150%'}}><br/><br/>Persoonlijke service</h4>
          <p class="card-text">Je bent van harte welkom in de winkel. Hier kun je onder het genot van een kopje koffie of thee op je gemak alles bekijken, voelen en passen. Indien je vragen hebt over de collectie beantwoorden we deze graag. Ook voorzien we je, waar gewenst, van een persoonlijk en eerlijk advies. </p>
          <ImageGallery galleryImages={galleryImages}/>
          <h4 class="card-title py-3" style={{fontSize:'150%'}}>Neem contact met ons op</h4>
          <p class="card-text">Ben je op zoek naar duurzame herenkleding? Dan kun je uitstekend terecht bij De Wolster. Heb je vragen over het aanbod of wil je graag een afspraak maken? Neem dan vrijblijvend contact op. Hiervoor kun je bellen naar 06-12574637 of een bericht achterlaten in het contactformulier op deze website. We staan je zo snel mogelijk te woord. Graag tot ziens bij De Wolster!</p>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Herenkleding
